<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">当前位置：<router-link :to="{ name: 'Index' }">首页</router-link>><router-link
            :to="{ name: 'Info' }">个人中心</router-link>>
          <router-link :to="{ name: 'Invoices_status' }">发票管理</router-link>
        </div>
      </div>
      <el-form :model="current" :rules="rules" ref="form">
        <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
          <div class="left_nav fs18">
            <router-link to="/info">基本信息</router-link>
            <router-link to="/certificate">证书抬头</router-link>
            <router-link to="/maintain_list">开票信息</router-link>
            <router-link to="/address_list">收件地址</router-link>
            <router-link to="/invoices_status" class="active">发票管理</router-link>
            <router-link to="/contract_management">合同管理</router-link>
            <router-link to="/change_password">修改密码</router-link>
            <router-link to="/change_phone">修改手机号</router-link>
            <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
          </div>
          <div class="right_box flex-grow-1">
            <div class="fs18 fwb pb20 pt40 mt10 border">开票信息</div>
            <el-form-item prop="s_title">
              <div class="pb30 flex-box" ref="form">
                <div class="invoice_title fs16 col9 mr10">发票抬头：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_title" placeholder="请输入发票抬头" />
              </div>
            </el-form-item>
            <el-form-item prop="s_texNo" ref="form">
              <div class="pb30 flex-box">
                <div class="invoice_title fs16 col9 mr10">纳税识别号：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_texNo" placeholder="请输入纳税识别号" />
              </div>
            </el-form-item>

            <el-form-item prop="s_address">
              <div class="pb30 flex-box" ref="form">
                <div class="invoice_title fs16 col9 mr10">详细地址：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_address" placeholder="请输入详细地址" />
              </div>
            </el-form-item>
            <el-form-item prop="s_tel" ref="form">
              <div class="pb30 flex-box">
                <div class="invoice_title fs16 col9 mr10">联系电话：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_tel" placeholder="请输入联系电话" />
              </div>
            </el-form-item>

            <el-form-item prop="s_bankName" ref="form">
              <div class="pb30 flex-box">
                <div class="invoice_title fs16 col9 mr10">开户行：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_bankName" placeholder="请输入开户行" />
              </div>
            </el-form-item>
            <el-form-item prop="s_account" ref="form">
              <div class="pb30 flex-box">
                <div class="invoice_title fs16 col9 mr10">银行账号：</div>
                <el-input type="text" class="invoice_input fs16" v-model="current.s_account" placeholder="请输入银行账号" />
              </div>
            </el-form-item>
            <div class="save" @click="submit">提交</div>
          </div>
        </div>
      </el-form>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import cookie from "vue-cookies";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
export default {
  data() {
    return {
      is_type: 0, //0:新增,1:编辑
      current: {
        s_title: '',
        s_texNo: '',
        s_address: '',
        s_tel: '',
        s_bankName: '',
        s_account: ''
      },
      rules: {
        s_title: [{
          required: true,
          message: '请输入发票抬头',
          trigger: ['change', 'blur'],
        }],
        s_texNo: [{
          required: true,
          message: '请输入纳税识别号',
          trigger: ['change', 'blur'],
        }],

        s_tel: [{
          required: true,
          message: '请输入联系电话',
          trigger: ['change', 'blur'],
        }],
        s_address: [{
          required: true,
          message: '请输入详细地址',
          trigger: ['change', 'blur'],
        }],

        s_bankName: [{
          required: true,
          message: '请输入开户行',
          trigger: ['change', 'blur'],
        }],
        s_account: [{
          required: true,
          message: '请输入银行账号',
          trigger: ['change', 'blur'],
        }],
      },
    };
  },

  // 页面渲染完执行
  created() {
    let _that = this;
    _that.detail();
  },
  methods: {
    submit() {
      let that = this
      that.$refs['form'].validate(valid => {
        if (valid) {
          let res = that.current
          let user = cookie.get("huanbaoqiao_userinfo");
          let item2 = {
            title: res.s_title,
            texNo: res.s_texNo,
            address: res.s_address,
            tel: res.s_tel,
            bankName: res.s_bankName,
            account: res.s_account,
            userId: user.id,
          };
          if (item2.tel != '') {
            var patrn = /^1[3456789]\d{9}$/; //手机电话
            var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
            if (patrn.test(item2.tel) == false && zjdh.test(item2.tel) == false) {
              that.$message.error("请输入正确的联系方式！");
              return;
            }
          }
          // const reg = /^([1-9]{1})(\d{15}|\d{18})$/;
          // if (!reg.test(item2.account)) {
          //   that.$message.error("请输入正确的银行卡号！");
          //   return;
          // }
          let is_type = that.is_type
          if (is_type == 1) {
            item2.id = res.id
            that.$api.updateInvoiceMessages(item2).then((res) => {
              if (res.resCode == 0) {
                that.is_type = 0
                that.$message.success('提交成功')
                setTimeout(function(){
                  that.$router.push("/invoices_status");
                },500)
              } else {
                that.$message.error(res.resMsg);
              }
            }).catch((err) => {
              console.log(err);
            });
          } else {
            // var data = [item2]
              var data = item2
            that.$api.addInvoices(data, 'application/json').then((res) => {
              if (res.resCode == 0) {
                that.is_type = 0
                that.$message.success('提交成功')
                setTimeout(function(){
                  that.$router.push("/invoices_status");
                },500)
              } else {
                that.$message.error(res.resMsg);
              }
            }).catch((err) => {
              console.log(err);
            });
          }
        } else {
          that.$message.error('请完善信息');
        }
      })
    },

    detail() {
      let that = this
      that.$api.userInvoices({}).then((res) => {
        if (res.resCode == 0) {
          if (res.root.length > 0) {
            that.current = {
              id: res.root[0].id,
              s_title: res.root[0].title,
              s_texNo: res.root[0].texNo,
              s_address: res.root[0].address,
              s_tel: res.root[0].tel,
              s_bankName: res.root[0].bankName,
              s_account: res.root[0].account
            },
              that.is_type = 1
          }
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });


    },


    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });
          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },


  }


}
</script>

